<template>
    <div class="card-ticket">
        <div class="row">
            <div class="xs12 pa-3 pb-2">
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{ GioXuatBen }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-road-variant"></i>
                    </div>
                    <div :class="textRight">
                        {{
                            `${item.TenBenXeDi} - ${item.TenBenXeDen}${
                                item.MaTuyen ? ` (${item.MaTuyen})` : ``
                            }`
                        }}
                    </div>
                </div>
                <div :class="rowText" class="color-primary">
                    <div class="mr-2">
                        <i class="mdi mdi-bus-stop"></i>
                    </div>
                    <div :class="textRight">
                        {{ `${item.TenBenXeDi}` }}
                    </div>
                </div>
                <div :class="rowText" class="color-success">
                    <div class="mr-2">
                        <i class="mdi mdi-map-marker-down"></i>
                    </div>
                    <div :class="textRight">{{ `${item.TenBenXeDen}` }}</div>
                </div>

                <div :class="rowText" class="color-warning">
                    <div class="mr-2">
                        <i class="mdi mdi-account-group-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.TenDoiXe }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="xs12">
                <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                    <DxButton
                        text="Ký lệnh"
                        type="default"
                        styling-mode="text"
                        width="100%"
                        :disabled="
                            !$Helper.KiemTraQuyen(
                                $t('QuyenLenhDienTu.LENHDIENTU'),
                                $t('QuyenLenhDienTu.KyLenh'),
                            )
                        "
                        @click="KyLenh(item)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    computed: {
        GioXuatBen() {
            return (this.item.GioXuatBen || "").substr(0, 5);
        },
    },
    methods: {
        KyLenh() {
            this.$store.commit("DVVTLenh/Reset_ThongTinKyLenh");
            this.$router.push({
                path: "/DNVT-Quan-Ly-Lenh/Ky-Lenh",
                query: {
                    IdDnvtTuyen: this.item.IdDnvtTuyen,
                    IdBenDi: this.item.IdBenXeDi,
                    IdBenDen: this.item.IdBenXeDen,
                    TenTuyen: `${this.item.TenBenXeDi} - ${this.item.TenBenXeDen}${
                        this.item.MaTuyen ? ` (${this.item.MaTuyen})` : ``
                    }`,
                    TenBenDi: this.item.TenBenXeDi,
                    TenBenDen: this.item.TenBenXeDen,
                    MaTuyen: this.item.MaTuyen,
                    ID_KeHoach: this.item.ID_KeHoach,
                    IdDoiXe: this.item.IdDoiXe,
                },
            });
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}
</style>
